<template>
  <path id="Tracé_567" data-name="Tracé 567" d="M15.809,54.186.41,38.787a1.4,1.4,0,0,1,0-1.979l5.6-5.6a1.4,1.4,0,0,1,1.979,0l1.737,1.737c1.256-1.2,3.322-3.176,4.682-4.538C16.017,26.91,18.677,25.2,21,25.2h7c1.5,0,2.842,1.025,4.023,1.928A8.961,8.961,0,0,0,33.283,28H41.6a5.872,5.872,0,0,1,4.044,1.41l4.717-2.449c1.66-3.147,3.344-5.234,6.32-5.927,3.286-.759,5.955.466,7.042,1.931a1.4,1.4,0,0,1,.164,1.383A75.719,75.719,0,0,1,56.645,35.93c-.093.1-9.636,10.268-12.245,10.268H23.8a2.306,2.306,0,0,0-.726.1l.315.315a1.4,1.4,0,0,1,0,1.978l-5.6,5.6a1.4,1.4,0,0,1-1.978,0ZM3.38,37.8,16.8,51.217l3.62-3.62L7,34.178Zm17.642,6.445A4.458,4.458,0,0,1,23.8,43.4H44.263c1.589-.663,8.686-7.533,10.292-9.333a80.206,80.206,0,0,0,6.332-9.918,5.033,5.033,0,0,0-3.57-.386c-1.816.424-3.039,1.7-4.673,4.878a1.412,1.412,0,0,1-.6.6l-4.97,2.581a4.674,4.674,0,0,1-1.366,4.5A5.806,5.806,0,0,1,41.6,37.8H29V35H41.6a3.147,3.147,0,0,0,2.192-.715A1.891,1.891,0,0,0,44.4,32.9c0-1.046-.868-2.1-2.8-2.1H33.2a5.153,5.153,0,0,1-2.882-1.449A6.137,6.137,0,0,0,28,28H21c-1.341,0-3.352,1.219-4.644,2.426-1.338,1.339-3.379,3.3-4.647,4.5ZM47.2,23.8V20.94c0-2.248-5.235-3.976-9.066-4.18a7.027,7.027,0,0,1-1.465,0c-3.83.2-9.067,1.932-9.067,4.18V23.8H24.8V20.94c0-3.357,3.779-5.381,7.582-6.335A7.6,7.6,0,0,1,30.4,9.4v-2a7.011,7.011,0,1,1,14,0v2A7.6,7.6,0,0,1,42.418,14.6C46.22,15.559,50,17.583,50,20.94V23.8ZM33.2,7.4v2a4.431,4.431,0,0,0,3.59,4.555c.21-.008.414-.012.61-.012s.4,0,.61.012A4.431,4.431,0,0,0,41.6,9.4v-2a4.217,4.217,0,1,0-8.4,0Z" transform="translate(0.614 0.5)" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</template>

<script>
export default {
name: "IconDotation"
}
</script>

<style scoped>

</style>